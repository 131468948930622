
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AssignmentIcon from '@material-ui/icons/Assignment';
import logo from './logo.svg';
import './App.css';

const TextInput = ({ id, label, value, onChange, onFocus }) => (
    <TextField
        id={id} label={label} multiline fullWidth style={{ marginBottom: '4rem' }}
        value={value} onChange={onChange} rows={'7'} onFocus={onFocus}
    />
);

const cleanText = text => text.replace(/[:;=+/<>*\-'"’“]/g, '');

const App = () => {
    const [input, setInput] = useState('');
    // const [output, setOutput] = useState('');
    // const outputRef = useRef(null);
    const inputHandler = value => {
        setInput(value);
    };
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                {/*<em style={{ color: '#F00' }}> Click on Output text field to copy to Clipboard </em>*/}
                <div style={{ width: '80%' }}>
                    <TextInput
                        id="input-text" label="Input text here:" value={input}
                        onFocus={() => {
                            if (navigator && navigator.clipboard) {
                                navigator.clipboard.readText()
                                    .then(text => inputHandler(text))
                                    .catch(err => {
                                        console.log('Failed to read clipboard contents');
                                    });
                            }
                        }}
                        onChange={({ target: { value } }) => setInput(value)}
                    />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <TextInput id="output-text" label="Output text here:" value={cleanText(input)} />
                        <Button
                            variant="contained" color="primary" style={{ height: '3rem' }}
                            title={'Copy to Clipboard'}
                            onClick={() => {
                                navigator.clipboard.writeText(cleanText(input))
                                    .then(() => {
                                        alert('SUCCESSFULLY COPIED!');
                                    })
                                    .catch(err => console.log(err));
                            }}
                        >
                            <AssignmentIcon />
                        </Button>
                    </div>
                </div>
            </header>
        </div>
    );
};
export default App;